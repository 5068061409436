<template>
    <div>
        <div id="page-wrapper" class="m-0 w-100" style="padding-top: 59px;">
            <div class="row">
                <b-navbar toggleable="md" variant="primary" type="dark" class="w-100" fixed="top">
                    <b-navbar-brand href="#" class="nav-bar-brand p-1">
                        <img src="../assets/logo-sidebar-left.png" alt="Sidebar" style="width: 30%; float: left;
                            max-width: 70px; border-radius: 0; margin-bottom: 0 !important;">
                        <img src="../assets/logo-sidebar-right.png" alt="Sidebar" style="width: 60%; float: right;
                            margin-top: 4px; max-width: 70px; border-radius: 0; margin-bottom: 0 !important;">
                    </b-navbar-brand>

                    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                    <b-collapse id="nav-collapse" is-nav>
                        <b-navbar-nav>
                            <template v-for="(item) in menu">
                                <b-nav-item :active="$route.meta.module === item.module" :to="item.route" :key="item.title + '-parent'"
                                            v-if="!item.children && showMenuOption(item)" class="ml-4" active-class="nav-link-active">
                                    <font-awesome-icon v-if="item.icon" :icon="item.icon" class="mr-1"></font-awesome-icon> {{ item.title }}
                                </b-nav-item>
                                <b-nav-item-dropdown right v-if="item.children" class="ml-4"
                                                     :key="item.title + '-collapse'" :text="item.title + ' '">
                                    <template v-for="child in item.children">
                                        <b-dropdown-item :active="$route.meta.module === child.module"
                                                           :to="child.route" v-if="!child.rule || $permission.check(child.rule)" :key="child.title + '-children'">
                                            <font-awesome-icon v-if="child.icon" :icon="child.icon"></font-awesome-icon> {{ child.title }}
                                        </b-dropdown-item>
                                    </template>
                                </b-nav-item-dropdown>
                            </template>
                        </b-navbar-nav>
                        <b-navbar-nav class="ml-auto">
                            <b-nav-item-dropdown right toggle-class="custom-nav-link" no-caret>
                                <template v-slot:button-content>
                                    <b-row class="text-white">
                                        <b-col class="text-right pr-0">
                                            {{ user.name }}
                                            <br>
                                            <small>{{user.email}}</small>
                                        </b-col>
                                        <b-col class="align-middle">
                                            <h1><font-awesome-icon icon="user-circle" class="text-white"></font-awesome-icon></h1>
                                        </b-col>
                                    </b-row>
                                </template>
<!--                                <b-dropdown-item :to="'user'">-->
<!--                                    <b-row>-->
<!--                                        <b-col cols="3" class="p-0"><font-awesome-icon icon="users"></font-awesome-icon></b-col>-->
<!--                                        <b-col class="p-0">Users</b-col>-->
<!--                                    </b-row>-->
<!--                                </b-dropdown-item>-->
                                <b-dropdown-item @click="logOut">
                                    <b-row>
                                        <b-col cols="3" class="p-0"><font-awesome-icon icon="sign-out-alt"></font-awesome-icon></b-col>
                                        <b-col class="p-0">Log out</b-col>
                                    </b-row>
                                </b-dropdown-item>
                            </b-nav-item-dropdown>
                        </b-navbar-nav>
                    </b-collapse>
                </b-navbar>
            </div>
<!--            <div class="row wrapper border-bottom foreground page-heading">-->
<!--                <Breadcrumb :title="breadcrumb.title" :path="breadcrumb.path"></Breadcrumb>-->
<!--            </div>-->
            <div class="container-fluid wrapper wrapper-content">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import $permission from '@/js/permission';

export default {
    data() {
        return {
            user: {
                name: 'User Name',
                email: '',
                username: '',
                roles: ''
            },
            breadcrumb: {
                title: '',
                path: []
            },
            menu: [
                /*{
                    title: 'Home',
                    icon: 'home',
                    module: 'home',
                    route: { name: 'Home' }
                },*/
                {
                    title: 'Inventory',
                    icon: 'cubes',
                    module: 'inventory-data',
                    route: { name: 'InventoryDataIndex' }
                },
                {
                    title: 'Log',
                    icon: 'file-alt',
                    module: 'movements',
                    route: { name: 'InventoryMovementIndex' }
                },
                {
                    title: 'Repairs',
                    icon: 'wrench',
                    module: 'repair',
                    route: { name: 'RepairIndex' }
                },
                /*{
                    title: 'Inventory',
                    header: true,
                    children: [
                        {
                            title: 'Inventory',
                            icon: 'cube',
                            module: 'inventory',
                            route: { name: 'InventoryIndex' }
                        },
                        {
                            title: 'Movements',
                            icon: 'cubes',
                            module: 'movements',
                            route: { name: 'InventoryMovementIndex' }
                        }
                    ]
                },*/
                {
                    title: 'Catalogs',
                    header: true,
                    children: [
                        {
                            title: 'Parts',
                            icon: 'microchip',
                            module: 'item',
                            route: { name: 'ItemIndex' }
                        }
                    ]
                }
            ]
        };
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('user'));
    },
    methods: {
        logOut() {
            // document.body.classList.remove('margin-left-sidebar');
            // document.body.classList.add('no-margin-sidebar');

            localStorage.removeItem('token');
            localStorage.removeItem('user');

            this.$router.replace({ name: 'Login' });
        },
        showMenuOption(item) {
            if (item.children) {
                for (const child of item.children) {
                    if (!child.rule || $permission.check(child.rule)) {
                        return true;
                    }
                }
            } else {
                return !item.rule || $permission.check(item.rule);
            }

            return false;
        }
    }
};
</script>
<style>
    .nav-bar-brand {
        background: white !important;
        border-radius: 5px;
    }

    .nav-link {
        padding: 0 !important;
        color: white !important;
    }

    .nav-link-active {
        font-weight: bold !important;
    }
</style>